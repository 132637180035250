body, #root{
  width:100%;
  height:100%;
}

.flex-1 {
  flex: 1;
}

.flex-container{
  width: 100%;
  height: 100%;
  display: flex;
}

.flex-column{
  flex-direction: column;
}

.flex-container-center{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-enter {
  opacity: 0.01;
}

.card-enter.card-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.card-exit {
  opacity: 1;
}

.card-exit.card-exit-active {
  opacity: 0.01;
  transition: opacity 0ms ease-in;
}
